import React, { Component } from 'react'
import * as d3 from 'd3'
import axios from 'axios'
import { global } from '../../../config/global'

class ScoreChart extends Component {

    componentDidMount() {
        axios.get(global.server + "/professors/scorecounts/" + this.props.id).then((response) => {
            this.drawBarChart(response.data);
        },
        (err) => {
            console.log("SERVER IS DOWN");
        })
    }

    drawBarChart(data) {
        var margin = { top: 20, right: 0, bottom: 16, left: 0 },
        width = 200,
        height = 190 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3.select(this.refs.canvas)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");


        var xScale = d3.scaleBand().range([-1, width]).padding(0.15),
            yScale = d3.scaleLinear().range([height, 25]);

        var g = svg.append("g")
            .attr("transform", "translate(" + 0 + "," + 0 + ")");

        xScale.domain([1,2,3,4,5]);
        yScale.domain([0, d3.max(data, function (d) { return d.val; })]);

        g.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(xScale).ticks(5))
            .append("text")
            .attr("y", height - 20)
            .attr("x", width - 40)
            .attr("text-anchor", "end")
            .attr("stroke", "black")
            .text("culpameter");

        g.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("fill", "var(--blue)")
            .attr("x", function (d) { return xScale(d.score) + 20; })
            .attr("y", function (d) { return yScale(d.val); })
            .attr("width", xScale.bandwidth())
            .attr("height", function (d) { return height - yScale(d.val); });

        g.selectAll("text2")
            .data(data).enter()
            .append("text")
            .attr("x", (dataPoint, i) => xScale(dataPoint.score) + 30)
            .attr("y", (dataPoint, i) => yScale(dataPoint.val) - 8)
            .attr("color", "black")
            .attr("font-size", "12px")
            .text(dataPoint => dataPoint.val !== 0 ? dataPoint.val : null)

    }
    render() { return <div ref="canvas"></div> }
}
export default ScoreChart