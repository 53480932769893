module.exports = {
    global:{
        sem1: "spring 2020", 
        sem2: "fall 2020", 
        name: "culpameter", 
        slogan: "this is culpameter.", 
        about: "your semester starts here.",
        server: process.env.REACT_APP_API, 
        faq: [
            {question: "What is a culpameter?", answer:"A culpameter is a numerical score assigned to a professor based on the sentiments expressed in CULPA reviews. We used natural language processing techniques to calculate them. The scores are not always perfect, but we hope they are able to guide your registration process."}, 
            {question: "I can’t find a professor!", answer:"Currently, we only show professors who have reviews in CULPA. We highly encourage you to submit a review for that professor, so it will show up on here as well! "}, 
            {question: "Is this site made by/affiliated with CULPA?", answer:"It is not. We admire the platform CULPA has created, and we think that adding this layer will help students find their next favorite professors even more easily."}, 
            {question: "Where is the data from?", answer:"The reviews and the information associated with professors is from the CULPA website, while the course offerings for each semester are from Columbia’s directory of classes."}, 
        ]
    }
}