import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import QueryPage from '../../components/query/QueryPage'

const Query = ({ location }) => (
    <>
        {/* <QueryPage></QueryPage> */}
        <Route  render={props => <QueryPage {...props} />} />

    </>
)

export default withRouter(Query)
