import React from 'react'
import Header from './global/Header'
import Footer from './global/Footer'
import i18n from '../../i18n'

// const footerItems = [
//   'landingPage.faq',
//   'landingPage.siteNotice',
//   'landingPage.legal',
//   'landingPage.arbitrationBoard',
//   'landingPage.vwFSAG',
//   'landingPage.vwAG',
// ]

const PageLayout = ({ children }) => (
  <>
    <Header title={i18n('appTitle')} />
    {children}
    <Footer/>
  </>
)

export default PageLayout