import React from 'react'
import './Loading.scss'

class Loading extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div id="loading">
                <div className="loadingio-spinner-ball-1ebmtkkee8t">
                    <div className="ldio-2xo812k5n4l">
                        <div></div>
                    </div>
                </div>
            </div>

        );
    }

}

export default Loading