import React, { Component } from 'react'
import * as d3 from 'd3'
import axios from 'axios'
import { global } from '../../../config/global'


class TimeGraph extends Component {
    componentDidMount() {
        axios.get(global.server + "/professors/movingaverage/" + this.props.id).then((response) => {
            this.drawTimeGraph(response.data);
        },
        (err) => {
            console.log("SERVER IS DOWN");
        })
    }


    drawTimeGraph(data) {
        // set the dimensions and margins of the graph
        var margin = { top: 10, right: 8, bottom: 32, left: 14 },
            width = 180,
            height = 190 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        var svg = d3.select(this.refs.canvas)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var min = Math.min(4, data.length-1);
        // Add X axis --> it is a date format
        var x = d3.scaleLinear()
            .domain(d3.extent(data, function (d) { return d.year; }))
            .range([0, width]);
        var xAxis = d3.axisBottom(x)
            .ticks(min)
            .tickFormat(d3.format('.0f'));
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
            .selectAll("text")	
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)");
    



        // Add Y axis
        var y = d3.scaleLinear()
            .domain([1, 5])
            .range([height, 0]);
        var yAxis = d3.axisLeft(y)
            .ticks(5)
            .tickFormat(d3.format('.0f'));
        svg.append("g")
            .call(yAxis);

        // Add the line
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "var(--highlight2)")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return x(d.year) })
                .y(function (d) { return y(d.score) })
            )

        // })

    }
    render() { return <div ref="canvas"></div> }
}
export default TimeGraph