import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import ProfPage from '../prof/ProfPage'


const Professor = ({ match }) => (
    <>
     <Route path={`${match.path}/:prof`} render={props => <ProfPage id={props.match.params.prof} {...props} />} />
    </>
)

export default withRouter(Professor)
