import React from 'react'
import './Footer.scss'
import { global } from '../../../config/global'

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      footerLogo: {url:"", alt:""},
    }
  }

  async componentDidMount() {
  }

  render() {
    return <>
      <footer>
        <div className="container2">
          <div className="footer-links">
            <a className="logo" href='/'>{global.name}</a>
            /
            <a href='/about'>about</a>
            <a className="email" href='mailto:culpameter@gmail.com'>culpameter@gmail.com</a>
          </div>
          <div className="lower-footer">
          {global.name} 2020
          </div>
        </div>

      </footer>
    </>
  }

}

export default Footer


