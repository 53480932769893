import React, { useEffect, useState } from 'react'
import './QueryPage.scss'
import TableRow from '../table/TableRow'
import { global } from '../../../config/global'
import SearchBar from '../global/SearchBar'
import FilterGroup from '../filters/FilterGroup'
import MobileHome from '../home/MobileHome'
import Loading from '../global/Loading'
import NoResults from './NoResults'


const fetchData = async (path) => {
    try {
        const response = await fetch(global.server + path, {method: 'GET'});
        const res = await response.json();
        if (response.ok) {
            return { dat: res, error: '' };
        }
        return { dat:null, error: response };

    } catch (err) {
        return { dat: null, error: err };
    }
}

function QueryPage(props) {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState("home");
    const [query, setQuery] = useState(props.history.location.state ? props.history.location.state.query : (props.location.state ? props.location.state.query : ''));
    const [fullData, setFullData] = useState(null);
    const [filter, setFilter] = useState({sem:"all", score:1, dept:0});
    const [lock, setLock] = useState(props.location.state ? true : false);
    const [data, setData] = useState([]);
    const [sortRule, setSortRule] = useState(1);
    const [sortCol, setSortCol] = useState("prof");
    const [error, setError] = useState("");
    const sortClasses = ["none", "up", "down"];

    useEffect(() => {
        async function getFullData() {
            const { dat, error } = await fetchData("/search/" + window.location.search.replace('?', ''));
            if (dat) {
                const d = dat.filter(function (entry) { return (entry.prof_name != null) });
                setFullData(d);
                setData([]);
                handleSearchChange(query, d);
                setLoading(false);
            }
            else {
                setError("error");
            }
        }

        if (!fullData){
            const querystring = require('querystring');
            if (window.location.search) {
                setPage("query");
                setFilter(querystring.parse(window.location.search.replace('?', '')));
            }
            getFullData();
        }
    }, [fullData]);

    function handleSearchChange(q, dat = fullData) {
        if (q != query) {
            setLock(false);
        }
        if (q) {
            setQuery(q);
        }
        var d = q ? dat.filter(function (entry) { return (entry.course_number.toLowerCase().includes(q) || entry.name.toLowerCase().includes(q) || entry.prof_name.toLowerCase().includes(q)) }) : dat;

        var obj = {};
        d.map(row => {
            if (!obj[row.prof_id_culpa]) {
                obj[row.prof_id_culpa] = { "courses": {}, profName: row.prof_name, profId: row.prof_id_culpa, score: row.score, num_reviews: row.num_reviews };
            }
            if (!obj[row.prof_id_culpa]["courses"][row.course_number]) {
                obj[row.prof_id_culpa]["courses"][row.course_number] = { id: row.course_number, name: row.name, sem: row.semester ? [row.semester.toLowerCase()] : [] };
            }
            else {
                if (row.semester) {
                    obj[row.prof_id_culpa]["courses"][row.course_number].sem.push(row.semester.toLowerCase());
                }
            }
        });
        var res = Object.values(obj);
        sortData(sortCol, sortRule, res.slice(0, 50));
    
    }

    function sortData(col, i, dat) {
        switch (col) {
            case 'review':
                setData(i === 1 ? dat.sort((a, b) => (a.num_reviews < b.num_reviews) ? 1 : -1) : (i === 2 ? dat.sort((a, b) => (a.num_reviews > b.num_reviews) ? 1 : -1) : dat));
                break;
            case 'score':
                setData(i === 1 ? dat.sort((a, b) => (a.score < b.score) ? 1 : -1) : (i === 2 ? dat.sort((a, b) => (a.score > b.score) ? 1 : -1) : dat));
                break;
            default:
                setData(i === 1 ? dat.sort((a, b) => (a.profName.replace(' ', '').toLowerCase() > b.profName.replace(' ', '').toLowerCase()) ? 1 : -1) : (i === 2 ? dat.sort((a, b) => (a.profName.replace(' ', '').toLowerCase() < b.profName.replace(' ', '').toLowerCase()) ? 1 : -1) : dat));
                break;
        }
    }

    function goSearch(q) {
        setQuery(q);
        setPage('query');
        window.scrollTo(0, 0);
        var params = filter;
        var query = Object.keys(params).map(k => k + '=' + params[k]).join('&');
        props.history.push({
            pathname: '/search',
            search: '?' + query
        });
    }

    function sortClick(col) {
        var i = (sortCol === col ? (sortRule + 1) % 3 : 1);
        setSortCol(col);
        setSortRule(i);
        sortData(col, i, data);
    }


    async function handleStateChange(key, value) {
        var params = filter;
        params[key] = value;
        setFilter(params);
        var queryString = Object.keys(params).map(k => k + '=' + params[k]).join('&');

        const { dat, error } = await fetchData("/search/" + queryString);
        if (dat) {
            const d = dat.filter(function (entry) { return (entry.prof_name != null) });
            setFullData(d);
            setPage("query");
            handleSearchChange(query, d);
        }
        else {
            setError("error");
        }
    }

    function addHistory(q) {
        props.history.push({
            pathname: '/search',
            search: '?' + q,
            state: { query: query }
        });
    }

    return (
        <>
        <div id="query">
            {loading && page === 'query' ?
                <Loading></Loading> : loading && page === 'home' ? <Loading></Loading> : 
                <div className="container2">
                    <div className={"sidenav " + (page)} >
                        <div className="slogan">
                            <h2>{global.slogan}</h2>
                            <p>Finding your next favorite professors just got easier with culpameter. Start your search here.</p>
                            {/* <button onClick={() => { this.navigateTo('/about') }}>more about culpameter</button> */}
                        </div>
                        <SearchBar query={lock ? query : null} page={page} filter={filter} handleSearchChange={page === 'query' ? handleSearchChange : goSearch}></SearchBar>
                        {loading ? null : <FilterGroup page={page} handleStateChange={handleStateChange} filter={filter}></FilterGroup>}
                    </div>

                    {page === 'home' ? null
                        : 
                        <div className="main">

                            {/* search bar */}

                            {data.length === 0 ? <NoResults></NoResults> :

                                <div className="table">
                                    <table>
                                        <tr>
                                            <th><a href="#" className={"sort-by " + (sortCol === 'prof' ? sortClasses[sortRule] : "")} id="sort_prof" onClick={() => { sortClick("prof") }}>professor</a><div className="descript">showing {data.length} results</div></th>
                                            <th><div>courses</div><div className="legend" ><span className="highlight-1">{global.sem1}</span> <span className="highlight-2">{global.sem2}</span></div></th>
                                            <th><a href="#" className={"sort-by " + (sortCol === 'score' ? sortClasses[sortRule] : "")} id="sort_score" onClick={() => { sortClick("score") }}>culpameter</a> <div className="descript">out of 5</div></th>
                                            <th><a href="#" className={"sort-by " + (sortCol === 'review' ? sortClasses[sortRule] : "")} id="sort_review" onClick={() => { sortClick("review") }}>reviews</a><div className="descript"># of reviews</div></th>
                                        </tr>

                                        {data.map(function (row, i) {
                                            return (
                                                <TableRow filter={filter} key={i} profId={row.profId} prof={row.profName} courses={row.courses} score={row.score} num_reviews={row.num_reviews} addHistory={addHistory} ></TableRow>
                                            );
                                        })}
                                    </table>

                                </div>}
                        </div>}
                </div>}
        </div>

        <div id="mobile-home">
            <MobileHome></MobileHome>
        </div>
        </>
    );
}

export default QueryPage