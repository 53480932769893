import React from 'react'
import './ToggleRow.scss'

class ToggleRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    async componentDidMount() {

    }

    render() {
        return (
        <li id="toggle-row">
            <div className="title" onClick={() => this.toggleCourse(this.props.i)} >
                <span className = "id">[{this.props.id}]</span>
                <div className = "name">{this.props.name}</div>
                <span className="sem">{this.props.sem.join(', ')}</span>
                <i id={"expand-button-" + this.props.i} className="fas fa-plus-circle"></i>
            </div>

            <div id={"course-toggle-" + this.props.i} className="toggle">
                other professors: 
                <div className="other-prof-links">
                    {this.props.profs.map((prof, i) => <><a href={prof.id}>{prof.name}</a>  {(i !== this.props.profs.length - 1 ? ', ' : '')}</>)}
                </div>
            </div>
        </li>
        );
    }

    toggleCourse(id){
        var e = document.getElementById("course-toggle-" + id);
        e.classList.toggle('open');
        var o = document.getElementById("expand-button-" + id);
        o.classList.toggle('fa-plus-circle');
        o.classList.toggle('fa-minus-circle');
    }
}

export default ToggleRow