import React from 'react'
import './SelectSemester.scss'
import { global } from '../../../config/global'

class SelectSemester extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sems:{
        'sem1': global.sem1,
        'sem2':global.sem2
      }
    }
  }

  async componentDidMount() {
  }

  render() {
    return <>
      <div id="semester" className={this.props.page}>
        <div className="dropdown">
          <button className="dropbtn"><span>SEMESTER:</span>{this.props.sem === 'all' ? 'all' : global[this.props.sem]}</button>
          <div className="dropdown-content">
            <label>active semester</label>
            <button className={'first ' + (this.props.sem === 'all' ? 'selected' : '')} onClick={() => this.selectButton('all')}>all</button>
            <button className={this.props.sem === 'sem1' ? 'selected' : ''} onClick={() => this.selectButton('sem1')}>{global.sem1}</button>
            <button className={'last ' + (this.props.sem === 'sem2' ? 'selected' : '')} onClick={() => this.selectButton('sem2')}>{global.sem2}</button>
          </div>
        </div>
      </div>
    </>
  }

  select() {
    document.getElementById(this.props.sem).classList.add('sem-selected');
  }

  selectButton(id) {
    this.props.handleStateChange("sem", id);
  }

}

export default SelectSemester