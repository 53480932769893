import React from 'react'
import './SearchBar.scss'
import { Link, Redirect } from 'react-router-dom'


class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.state = {
            query: this.props.query ? this.props.query : "",
            go: false
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    async componentDidMount() {

    }

    render() {
        return (
            this.state.go ? <Redirect to={{ pathname: '/search/?' + Object.keys(this.props.filter).map(k => k + '=' + this.props.filter[k]).join('&'), state: { query: this.state.query, sem: this.props.filter.sem, dept: this.props.filter.dept, score: this.props.filter.score } }}></Redirect> :
                (<div className={"search " + this.props.page}>
                    <input onKeyDown={this.handleKeyDown} value={this.props.query} id={this.props.page === 'mobile-home' ? "searchbar-dummy" : "searchbar"} className="search-bar" onFocus={(this.props.page === "mobile-home" ? this.props.handleClick : null)} onChange={(this.props.page === 'query' ? this.handleChange : this.search)} type="text" placeholder="search by professor, course name, etc."></input>
                    {this.props.page === 'prof' && this.state.query !== '' ? <Link className="link" to={{ pathname: '/search/?' + Object.keys(this.props.filter).map(k => k + '=' + this.props.filter[k]).join('&'), state: { query: this.state.query, sem: this.props.filter.sem, dept: this.props.filter.dept, score: this.props.filter.score } }}>go</Link> : null}
                </div>)
        );
    }

    handleKeyDown(e) {
        if (e.key === 'Enter' && this.props.page === 'prof') {
            this.setState({ go: true })
        }
    }

    handleChange(e) {
        this.props.handleSearchChange(e.target.value.toLowerCase());
    }
    search(e) {
        this.setState({
            query: e.target.value
        })
        this.props.handleSearchChange(e.target.value.toLowerCase());
    }
}

export default SearchBar