import React from 'react'
import './MobileHome.scss'
import { global } from '../../../config/global'
import SearchBar from '../global/SearchBar';

class MobileHome extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            filter: { sem: 'all', score: 1, dept: 0 }
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    render() {
        return (
            <div className="container2">

                <div className="slogan">
                    <h2>{global.slogan}</h2>

                    <p>Finding your next favorite professors just got easier with culpameter. Start your search here.</p>
                    <SearchBar filter={this.state.filter} page="mobile-home" handleClick={this.handleClick}></SearchBar>

                    <button onClick={() => { this.navigateTo('/about')}}>Learn more</button>
                </div>


            </div>
        );
    }

    handleClick() {
        var x = document.getElementById("search-mobile-div");
        var y = document.getElementById("search-path");
        var z = document.getElementById("close-path");


        if (x.style.display === "block") {
            document.getElementById('burgerBtn').style.zIndex = '100';
            x.style.display = 'none';
            y.style.display = 'block';
            z.style.display = 'none';


        } else {
            document.getElementById('burgerBtn').style.zIndex = '-1';
            x.style.display = 'block';
            z.style.display = 'block';
            y.style.display = 'none';


            document.getElementById('search-mobile').style.color = 'white';
            document.getElementById("searchbar").focus();

        }
    }
}

export default MobileHome