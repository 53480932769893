import { createStore } from "redux";
import index from './reducers/index'

// we need to pass the initial state with the new look
function configureStore(state = { sessionState: {authenticated: false}}) {
  return createStore(
    index,
    state,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //eslint-disable-line
  );
}

export default configureStore;