import React from 'react'
import './SelectDepartment.scss'
import axios from 'axios'
import {global} from '../../../config/global'

class SelectDepartment extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.focus = this.focus.bind(this);
    this.focusOut = this.focusOut.bind(this);


    this.state = {
      depts:[{id_culpa:0, abbreviation:""}],
      selected : "",
      loading:true
    }
  }

  async componentDidMount() {
    document.getElementById("custom-select").addEventListener("focusout", this.focusOut);

    axios.get(global.server + "/departments").then((response) => {
        let data = response.data.depts;
        data.push({id_culpa:0, abbreviation:"all"});

        this.setState((state, props) => {
          return {
            depts:data, 
            loading:false
          };
        })
        this.fillSelect();
      },
      (err) => {
        console.log("SERVER IS DOWN");
      })
  }

  render() {

    return <>
        <div id="dept" className={this.props.page}>
            {/* <label className="label">department</label> */}
            <div id="custom-select">
                <select onChange={this.handleChange} id="select" onFocus={this.focus} value={this.props.dept} >
                {/* <input type="text" list="select" /> */}

                {/* <datalist id="select"> */}
                {this.state.depts.map(function (row, i) {
                    return (
                      <option value={row.id_culpa}>{row.abbreviation}</option>
                    );
                })}

                    {/* <option value={this.state.depts[this.state.depts.length-1].id_culpa}>{this.state.depts[this.state.depts.length-1].abbreviation}</option> */}
                {/* </datalist> */}

                </select>
                
            </div>
        </div>
    </>
  }

  focus(){
    document.getElementById("custom-select").classList.add("focus");
  }
  focusOut(){
  document.getElementById("custom-select").classList.remove("focus");

}


  handleChange(event) {
    document.getElementById("select").value = event.target.value;
    this.props.handleStateChange("dept", event.target.value);
  }

  fillSelect(){
    var select = document.getElementById("select");
    // for(var i = 0; i < this.state.depts.length-1; i++){
    //     var option = document.createElement("option"),
    //     txt = document.createTextNode(this.state.depts[i].abbreviation);
    //     option.appendChild(txt);
    //     option.setAttribute("value",this.state.depts[i].id_culpa);
    //     select.insertBefore(option,select.lastChild);
    // }
    select.value = this.props.dept;
  }
}

export default SelectDepartment