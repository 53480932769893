import i18next from 'i18next'



i18next.init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: process.env.REACT_APP_WEBSITE_LANG,
  // Using simple hardcoded resources for simple example
  // resources: {
  //   en: {
  //     translation: en,
  //   },
  //   nl: {
  //     translation: nl,
  //   },
  // },
})

const i18n = (key, data) => i18next.t(key, data)

export const changeLanguage = lang => {
  i18next.changeLanguage(lang)
}

export default i18n
