import React from 'react'
import './Workload.scss'
import axios from 'axios'
import {global} from '../../../config/global'
import ResizeObserver from 'resize-observer-polyfill';

class Workload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAll: [], 
            workload: {
                "": [
                    { name: "", date: "", comment: "" },
                ]
            }
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    async componentDidMount() {
        axios.get(global.server+"/professors/workload/" + this.props.id).then((response) => {
            this.setState((state, props) => {
                return {
                    workload: response.data,
                };
            })
        },
            (err) => {
                console.log("SERVER IS DOWN");
            })
    }

    render() {
        const ps = document.querySelectorAll('p');
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target.scrollHeight > entry.contentRect.height) {
                    entry.target.classList.add('truncated');
                }
            }
        });

        ps.forEach(p => {
            observer.observe(p);
        });

        var w = this.state.workload;
        return (
            <div id="workload-comments">
                {Object.keys(w).map(function (course, i) {
                    var full_comments = w[course];
                    var comments = (this.state.showAll.includes(i) ? full_comments : full_comments.slice(0,Math.min(full_comments.length, 3)));
                    return (
                        <div className="course-block">
                            <div className="course-div">
                                <div className="course"> <div className="course-name">{comments[0].name}</div>{course} </div>
                                {/* <button> 	&#8592; </button><button>&#8594;</button> */}
                            </div>
                            <div className="comment-block">
                                
                                { comments.map((comment, j) =>
                                    <div className="comments">
                                        <div className="date">{comment.date}</div>
                                        <div className="text">
                                            <input type="checkbox" id={"expanded-" + i + "-" + j}></input>
                                            <p>"{comment.comment}"</p>
                                            <label for={"expanded-" + i + "-" + j} role="button"></label>
                                        </div>
                                    </div>)}
                                {full_comments.length > 3 ? <button id={'show-all' + i} className="show-all" onClick={() => this.showAllComments(i)}> show all </button> : null}
                            </div>
                        </div>
                    );
                }, this)}
            </div>
        );
    }

    showAllComments(i){
        var newArr;
        if(this.state.showAll.includes(i)){
            newArr = this.state.showAll.filter(function(ele){return ele !== i; });
            this.setState({
                showAll: newArr
            });
            document.getElementById('show-all' + i).innerHTML = 'show all';
        }
        else{
            newArr = this.state.showAll;
            newArr.push(i);
            this.setState({
                showAll: newArr
            });
            document.getElementById('show-all' + i).innerHTML = 'show less';
        }
    }
}


export default Workload