import React, { useEffect, useState } from 'react'
import './MobileQuery.scss'
import SearchBar from '../global/SearchBar'
import MobileResults from './MobileResults';
import FilterGroup from '../filters/FilterGroup'
import { global } from '../../../config/global'
import NoResults from './NoResults';


const fetchData = async (path) => {
    try {
        const response = await fetch(global.server + path, { method: 'GET' });
        const res = await response.json();
        if (response.ok) {
            return { dat: res, error: '' };
        }
        return { dat: null, error: response };

    } catch (err) {
        return { dat: null, error: err };
    }
}

function MobileQuery() {
    const [query, setQuery] = useState('');
    const [fullData, setFullData] = useState(null);
    const [filter, setFilter] = useState({ sem: "all", score: 1, dept: 0 });
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        async function getFullData() {
            const { dat, error } = await fetchData("/search/" + window.location.search.replace('?', ''));
            if (dat) {
                const d = dat.filter(function (entry) { return (entry.prof_name != null) });
                setFullData(d);
                setData([]);
                handleSearchChange(query, d);
                if (query !== '') {
                    document.getElementById("searchbar").value = query;
                }
            }
            else {
                setError("error");
            }
        }

        if (!fullData) {
            getFullData();
        }

    }, [fullData]);

    function handleSearchChange(q, dat = fullData) {
        setQuery(q);
        var d = q ? dat.filter(function (entry) { return (entry.course_number.toLowerCase().includes(q) || entry.name.toLowerCase().includes(q) || entry.prof_name.toLowerCase().includes(q)) }) : dat;

        var obj = {};
        d.map(row => {
            if (!obj[row.prof_id_culpa]) {
                obj[row.prof_id_culpa] = { "courses": [], profName: row.prof_name, profId: row.prof_id_culpa, score: row.score, num_reviews: row.num_reviews };
            }
            obj[row.prof_id_culpa]["courses"].push({ id: row.course_number, name: row.name, sem: [] });
        });
        var res = Object.values(obj);
        setData(res.slice(0, 50));
    }

    async function handleStateChange(key, value) {
        var params = filter;
        params[key] = value;
        setFilter(params);
        var queryString = Object.keys(params).map(k => k + '=' + params[k]).join('&');

        const { dat, error } = await fetchData("/search/" + queryString);
        if (dat) {
            const d = dat.filter(function (entry) { return (entry.prof_name != null) });
            setFullData(d);
            handleSearchChange(query, d);
        }
        else {
            setError("error");
        }
    }

    return (
        <div id="search-mobile-div">
            <div className="contain top">
                <SearchBar filter={filter} page='mobile' handleSearchChange={handleSearchChange}></SearchBar>
                <FilterGroup page='mobile' handleStateChange={handleStateChange} filter={filter}></FilterGroup>
            </div>
            <div className="contain">
                {data.length === 0 ? <NoResults></NoResults> :
                    <MobileResults data={data}></MobileResults>
                }
            </div>
        </div>
    );
}

export default MobileQuery