import React from 'react'
import './NoResults.scss'


class NoResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    openNewTab(path) {
        window.open(path);
    }

    render() {
        return (
            <div id="no-results">
                <div className="no-results"><span className="no-results">no results</span></div>
                <div className="no-prof">
                    <h5>Cant find a professor?</h5>
                    <p>Only professors who have CULPA reviews are displayed.</p>
                    <button className="about-us" onClick={() => { this.navigateTo('/about')}}>Learn more</button>

                    <button onClick={() => { this.openNewTab('http://culpa.info/review')}} formtarget="blank">Write a review on CULPA</button>
                </div>
            </div>

        );
    }

}

export default NoResults