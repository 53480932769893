import React from 'react'
import './FilterGroup.scss'
import SelectDepartment from '../filters/SelectDepartment'
import SelectScore from '../filters/SelectScore'
import SelectSemester from '../filters/SelectSemester'


class FilterGroup extends React.Component {

    constructor(props) {
        super(props);
        this.handleStateChange = this.handleStateChange.bind(this);

        this.state = {
            filter: this.props.filter
        }
    }

    async componentDidMount() {

    }

    render() {

        return <>
            <div id="filter-group" className={this.props.page}>
                <div className="filter-item"><SelectSemester sem={this.props.filter ? this.props.filter.sem : 'all'} handleStateChange={this.handleStateChange} page={this.props.page}></SelectSemester></div>
                <div className="filter-score"><SelectScore score={this.props.filter ? this.props.filter.score : 1} handleStateChange={this.handleStateChange} page={this.props.page}></SelectScore></div>
                <div className="filter-dept"><SelectDepartment dept={this.props.filter ? this.props.filter.dept : 0} handleStateChange={this.handleStateChange} page={this.props.page}></SelectDepartment></div>
 
                <button className="clear-button" onClick={() => { this.clearFilter() }}>clear all</button>
            </div>
        </>
    }

    clearFilter() {
        this.handleStateChange('dept', 0);
        this.handleStateChange('sem', 'all');
        this.handleStateChange('score', 1);
    }


    handleStateChange(key, value) {
        this.props.handleStateChange(key, value);
    }

}

export default FilterGroup


