import React from 'react'
import './Sentences.scss'
import axios from 'axios'
import { global } from '../../../config/global'

class Sentences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    async componentDidMount() {

        axios.get(global.server + "/professors/sentences/" + this.props.id).then((response) => {
            let data = response.data;
            this.setState((state, props) => {
                return {
                    data: data
                };
            })
        },
            (err) => {
                console.log("SERVER IS DOWN");
            })
    }

    render() {

        return (
        
            <section className="chunk sentences" id={this.state.data.length === 0 ? "invisible" : "sent"}>
                <h6>review highlights</h6>
                <div id="sentences" className={this.props.page}>
                    {/* <ul> */}
                    {this.state.data.map(function (sent, i) {
                        return (
                            <div className="sentence"><div className="date">{sent.submitted_date}</div> "{sent.sentence}" </div>
                        );
                    })}
                    {/* </ul> */}
                </div>

            </section>
        );
    }

}

export default Sentences