import React from 'react'
import './SelectScore.scss'

class SelectScore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div id="score" className={this.props.page}>

        <div className="dropdown ">
          <button className="dropbtn" onClick={() => this.toggleView()}><span>CULPAMETER:</span>{this.props.score}</button>
          <div className="dropdown-content" id="score-buttons">
            <label>minimum culpameter</label>
            <button className={'first ' + (this.props.score === 1 ? 'selected' : '')} onClick={() => this.selectScoreButton(1)}>1</button>
            <button className={this.props.score === 2 ? 'selected' : ''} onClick={() => this.selectScoreButton(2)}>2</button>
            <button className={this.props.score === 3 ? 'selected' : ''} onClick={() => this.selectScoreButton(3)}>3</button>
            <button className={'last ' + (this.props.score === 4 ? 'last selected' : '')} onClick={() => this.selectScoreButton(4)}>4</button>
          </div>
        </div>
      </div>
    );
  }

  toggleView(){
    //document.getElementById('score-buttons').classList.toggle('show');

    // var x = document.getElementById('score-buttons');
    // if (x.style.display == 'block'){
    //   x.style.display = 'none';
    // }
    // else{
    //   x.style.display = 'block';
    // }
  }

  selectScoreButton(id) {
    this.props.handleStateChange("score", id);
  }
}

export default SelectScore