import React from 'react'
import './Faq.scss'
import { global } from '../../../config/global'

function Faq() {
    function handleClick(e) {
        e.target.classList.toggle("active");
        var panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

    return (
        <div id="faq">
            <h5>FAQ</h5>
            {global.faq.map((row, i) =>
                <>
                    <button class="accordion" onClick={handleClick}>{row.question}</button>
                    <div class="panel">
                        <p>{row.answer}</p>
                    </div>
                </>

            )}
        </div>
    );
}

export default Faq