import React from 'react'
import './TableRow.scss'
import { global } from '../../../config/global'

class TableRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            culpaUrl: 'http://culpa.info/professors/'
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    render() {
        var courses = this.props.courses;
        return (
            <tr id="table-row">
                <td className="prof" onClick={() => { this.nav() }}>
                    {this.props.prof}
                </td>
                <td onClick={() => { this.nav() }}>
                    {/* <div id="divtoshow">hey</div> */}
                    <div className="course-div">
                        {Object.keys(courses).map(function (course, i) {
                            var obj = courses[course];
                            obj.c1 = '';
                            obj.c2 = '';
                            if (obj.sem.length === 2) {
                                obj.c1 = 'sem-one';
                                obj.c2 = 'sem-two';
                            }
                            else if (obj.sem[0] === global.sem1) {
                                obj.c1 = 'sem-one';
                            }
                            else if (obj.sem[0] === global.sem2) {
                                obj.c1 = 'sem-two';
                            }
                            return (
                                <div className="course"><span>{obj.name}</span>[<i className={"fas fa-splotch " + obj.c1}></i><i className={"fas fa-splotch " + obj.c2}></i>{obj.id}]</div>
                            );
                        })}
                    </div>
                </td>

                <td className="score" onClick={() => { this.navigateTo('/professors/' + this.props.profId) }}><span>{this.props.score}</span></td>
                <td><div className="num-reviews2"><div className="num-reviews">{this.props.num_reviews} reviews</div><a href={this.state.culpaUrl + this.props.profId} target="blank">CULPA</a></div></td>
            </tr>
        );
    }
    nav(){
        var params = this.props.filter;
        var query = Object.keys(params).map(k => k + '=' + params[k]).join('&');
        this.props.addHistory(query)
        this.navigateTo('/professors/' + this.props.profId);
    }
}

export default TableRow