import React from 'react'
import './About.scss'
import { global } from '../../../config/global'
import Faq from './Faq';


class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div id="about">
                <div className="container">
                    <h5>{global.about} </h5>
                    <p className="tagline">We are here to make course registration a little bit less stressful.</p>
                    <p>Supplementing the thousands of reviews already available on <a href="http://culpa.info/" target="blank" className="culpa">CULPA</a>, we created this platform to help you find the right professors and courses in an even more efficient manner. With the culpameter scores and other modules, we hope to streamline the review-reading process and help you make the most out of your semester! Please take a look at the FAQs below for more info.</p>
                </div>
                <div className="container">
                    <Faq></Faq>
                    <p className="contact">For any inquiries, please feel free to reach out to us at <a href='mailto:hello@culpas.com'>culpameter@gmail.com</a>.</p>

                </div>
            </div>
        );
    }

}



export default About