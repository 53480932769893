import React from 'react'
import './Header.scss'
import { global } from '../../../config/global'
import MobileQuery from '../query/MobileQuery'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  async componentDidMount() {
    var burgerBtn = document.getElementById('burgerBtn');
    var mobile = document.getElementById('mobile');

    burgerBtn.addEventListener('click', function () {
      mobile.classList.toggle('navigation');
    }, false);
  }

  render() {
    let path = document.location.href.substring(document.location.href.lastIndexOf('/') + 1);
    return <>
      <header id="head">




        <div className="container2">
          <div id="logo-div" className="logo-div"><a href="/">{global.name}</a></div>
          <div className="menu">
            {/* <SearchBar page="query" filter={{sem:'all', score:1, dept:0}}></SearchBar> */}
            {/* <div className="menu-item"><a href="/search" className={"button-menu " + (path === 'about' ? 'active' : '')}><i className="fas fa-search"></i>search</a></div> */}
            <div className="menu-item"><a href="/about" className={"button-menu " + (path === 'about' ? 'active' : '')}>about</a></div>


            {/* <label class="switch">
              <input type="checkbox" id="dark-mode-toggle"></input>
              <span class="slider round"></span>
            </label> */}

          </div>







        </div>
        <div className="mobile-menu">
          <div id="myLinks">
            <a href="/about">about</a>
          </div>
        </div>

        <div id="mobile">
          <div id="burgerBtn" onClick={() => this.toggleMenu()}></div>
          <div id="searchBtn">
            <i id="search-mobile" onClick={() => this.toggleSearch()}>
              <svg height="1em" width="1em" viewBox="0 0 24 25">
                <path id="search-path" d="m328.610352 24.2207031c-5.293946 0-9.610352-4.3164062-9.610352-9.6103515 0-5.28125004 4.316406-9.6103516 9.610352-9.6103516 5.28125 0 9.610351 4.32910156 9.610351 9.6103516 0 2.2724609-.799805 4.3544922-2.120117 6.0048828l6.436523 6.4365234c.228516.2285156.330079.5332031.330079.8378906 0 .6601563-.482422 1.2060547-1.180665 1.2060547-.317382 0-.62207-.1269531-.850585-.3427734l-6.449219-6.4746094c-1.612305 1.21875-3.618164 1.9423828-5.776367 1.9423828zm0-1.6123047c4.379882 0 7.998046-3.6054687 7.998046-7.9980468 0-4.3798828-3.618164-7.99804691-7.998046-7.99804691-4.392579 0-7.998047 3.61816411-7.998047 7.99804691 0 4.3925781 3.605468 7.9980468 7.998047 7.9980468z" transform="translate(-318 -5)"></path>
                <path id="close-path" d="m26.363961 24.7781746-7.0710678-7.0710678c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136s1.0236893-.3905243 1.4142136 0l7.0710678 7.0710678 7.0710678-7.0710678c.3905243-.3905243 1.0236893-.3905243 1.4142136 0s.3905243 1.0236893 0 1.4142136l-7.0710678 7.0710678 7.0710678 7.0710678c.3905243.3905243.3905243 1.0236893 0 1.4142136s-1.0236893.3905243-1.4142136 0l-7.0710678-7.0710678-7.0710678 7.0710678c-.3905243.3905243-1.0236893.3905243-1.4142136 0s-.3905243-1.0236893 0-1.4142136z" transform="translate(-16 -14)"></path>
              </svg>
            </i>
          </div>
        </div>
        <div className="search-mobile-div">
          <MobileQuery></MobileQuery>
        </div>

      </header>
    </>
  }

  toggleMenu() {
    var x = document.getElementById("myLinks");
    var v = document.getElementById('logo-div');

    if (x.style.display === "block") {
      x.style.display = 'none';
      v.style.zIndex = '100';
      document.getElementById("search-path").style.display = "block";
    } else {
      x.style.display = 'block';
      v.style.zIndex = '-1';
      document.getElementById("search-path").style.display = "none";

    }
  }

  toggleSearch() {
    var x = document.getElementById("search-mobile-div");
    var y = document.getElementById("search-path");
    var z = document.getElementById("close-path");


    if (x.style.display === "block") {
      document.getElementById('burgerBtn').style.zIndex = '100';
      x.style.display = 'none';
      y.style.display = 'block';
      z.style.display = 'none';


    } else {
      document.getElementById('burgerBtn').style.zIndex = '-1';
      x.style.display = 'block';
      z.style.display = 'block';
      y.style.display = 'none';


      //document.getElementById('search-mobile').style.color = 'white';
      document.getElementById("searchbar").focus();

    }
  }
}

export default Header