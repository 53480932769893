import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store'
import RouterScrollToTop from './components/RouterScrollToTop'

import AppRouter from './AppRouter'
import PageLayout from './components/PageLayout'

const store = configureStore();


export const App = () => (
  <Provider store={store}>
    <PageLayout>
      <Router basename={process.env.PUBLIC_URL}>
        <RouterScrollToTop>
          <AppRouter dark={true} />
        </RouterScrollToTop>
      </Router>
    </PageLayout>
  </Provider>
)
