import React from 'react'
import './MobileResults.scss'

class MobileResults extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            data: [
                {
                    profId: 339,
                    profName: "Donald Duck",
                    courses: [
                        { id: "COMS W4111", name: "Introduction to databases", dept: 0, sem: [] }
                    ],
                    score: 4.26,
                    num_reviews: 28
                }
            ],
        }
    }

    navigateTo(path) {
        document.location.href = path;
    }

    render() {
        return (
            <div id="mobile-results">
                {this.props.data.map(function (row, i) {
                    return (
                        <div className="list-item" onClick={() => { this.navigateTo('/professors/' + row.profId) }}>
                            <div className="flex">
                                <span className="name">{row.profName}</span>
                                <span className="score">{row.score}</span>
                            </div>
                            <div className="courses">
                                {row.courses.map(function (course, i) {
                                    course.c1 = '';
                                    course.c2 = '';
                                    if (course.sem.length === 2) {
                                        course.c1 = 'sem-one';
                                        course.c2 = 'sem-two';
                                    }
                                    else if (course.sem[0] === global.sem1) {
                                        course.c1 = 'sem-one';
                                    }
                                    else if (course.sem[0] === global.sem2) {
                                        course.c1 = 'sem-two';
                                    }
                                    return (
                                        <span className="course"><i className={"fas fa-splotch " + course.c1}></i><i className={"fas fa-splotch " + course.c2}></i>[{course.id}]</span>
                                    );
                                })}

                            </div>
                        </div>

                    );
                }, this)}
            </div>
        );
    }

    handleChange(e) {
        this.props.handleSearchChange(e.target.value.toLowerCase());
    }
    search(e) {
        this.setState({
            query: e.target.value
        })
        this.props.handleSearchChange(e.target.value.toLowerCase());
    }
}

export default MobileResults