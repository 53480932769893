import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Query from './components/pages/Query'
import Professor from './components/pages/Professor';
import About from './components/about/About';

class AppRouter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dark:false
  }
}

render() {
  return <>
   <div className='app'>
     <Switch>
       <Route
        exact
        path='/home'
        render={() => (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )}
      />
      <Route path='/about' render={props => <About dark={this.state.dark} {...props} />} />
      <Route path='/search' component={Query} />
      <Route path='/professors' component={Professor} />
      <Route path='/'       render={() => (
        <Redirect
          to={{
            pathname: '/search',
          }}
        />
      )} />
      <Route 
      render={() => (
        <Redirect
          to={{
            pathname: '/search',
          }}
        />
      )}
     />
    </Switch>
  </div>
</>
}

// export const AppRouter = () => (
//   <div className='app'>
//     <Switch>
//       <Route
//         exact
//         path='/home'
//         render={() => (
//           <Redirect
//             to={{
//               pathname: '/',
//             }}
//           />
//         )}
//       />
//       <Route path='/about' component={About} />
//       <Route path='/search' component={Query} />
//       <Route path='/professors' component={Professor} />
//       <Route path='/'       render={() => (
//         <Redirect
//           to={{
//             pathname: '/search',
//           }}
//         />
//       )} />
//       <Route 
//       render={() => (
//         <Redirect
//           to={{
//             pathname: '/search',
//           }}
//         />
//       )}
//      />
//     </Switch>
//   </div>
// )
}
export default AppRouter

