import React from 'react'
import './ProfPage.scss'
import '../global/SearchBar.scss'
import axios from 'axios'
import SearchBar from '../global/SearchBar'
import ToggleRow from '../toggle/ToggleRow'
import Workload from '../workload/Workload'
import ScoreChart from '../stats/ScoreChart'
import TimeGraph from '../stats/TimeGraph'
import { withRouter } from 'react-router-dom'
import { global } from '../../../config/global'
import Sentences from '../sentences/Sentences'
import Loading from '../global/Loading'



class ProfPage extends React.Component {

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);

        this.state = {
            profId: this.props.id,
            profName: '',
            score: null,
            courses: {
                "": {
                    name: "",
                    sem: [],
                    profs: [{ name: "", id: "" }]
                }
            },
            num_reviews: null,
            poll: {
                agree: null,
                higher: null,
                lower: null
            },
            loading: true, 
            server_fail: false, 
            // onboarded: cookie.load(this.props.id)
        }


    }

    async componentDidMount() {
        this.getData(this.state.profId);

    }

    render() {
        return (
        this.state.server_fail ? <div></div> : 
            (<div id='prof'>
                <div className="searchbar">
                    <div className="container2">
                        <SearchBar filter={{ sem: 'all', score: 1, dept: 0 }} page='prof' handleSearchChange={this.handleSearchChange}></SearchBar>
                    </div>
                </div>

                {this.state.loading ? 
                <Loading></Loading> :

                (<div className="container">

                    <div className="heading">
                        <h2>{this.state.profName}</h2>
                        <div className="culpa-links">
                            <a href={'http://culpa.info/professors/' + this.state.profId} target="blank">culpa reviews</a>
                            <a href={'http://culpa.info/reviews/new?professor_id=' + this.state.profId} target="blank">write a review (culpa)</a>
                        </div>
                    </div>

                    <div className="body">
                        {/* <div className="narrow"> */}
                        <section className="chunk culpameter">
                            <h6>culpameter</h6>
                            <div className="culpameter-wrapper">
                                <span className="score">{this.state.score}</span>
                                <div className="par">The culpameter score is calculated as an average of the sentiments expressed in <span className="num-reviews">{this.state.num_reviews} reviews</span>.</div>
                            </div>
                            {/* <AgreeDisagree poll={this.state.poll}></AgreeDisagree> */}
                        </section>

                        <section className="chunk visuals">
                            <h6>Visualize It</h6>
                            <ScoreChart id={this.state.profId}></ScoreChart>
                            <div className="par">A <span className="chart-title">histogram</span> showing the number of reviews in each score range</div>

                            <TimeGraph id={this.state.profId}></TimeGraph>
                            <div className="par">A <span className="chart-title">moving average</span> of {this.state.profName}'s culpameter score throughout the years</div>
                        </section>
                        {/* </div> */}

                        <div className="main">
                            <section className="chunk courses">
                                <h6>courses</h6>
                                <ul>
                                    {Object.entries(this.state.courses).map(function ([course, data], i) {
                                        return (
                                            <ToggleRow key={i} i={i} id={course} name={data.name} sem={data.sem} profs={data.profs}></ToggleRow>
                                        );
                                    })}
                                </ul>
                            </section>
                            <Sentences id={this.state.profId}></Sentences>
                            <section className="chunk workload">
                                <h6>workload comments</h6>
                                <Workload id={this.state.profId}></Workload>
                            </section>
                        </div>
                    </div>
                </div>)}
            </div>)
        );
    }

    navigateTo(path) {
        document.location.href = path;
    }

    handleSearchChange(query) {
        this.setState({
            query: query
        })
    }

    getData(id) {
        axios.get(global.server + "/professors/courses/" + id).then((response) => {
            this.setState((state, props) => {
                return {
                    courses: response.data,
                };
            })
        },
            (err) => {
                this.setState({
                    server_fail: true
                })
            })

        axios.get(global.server + "/professors/" + id).then((response) => {
            var data = response.data;
            this.setState((state, props) => {

                return {
                    profName: data.name,
                    score: data.score,
                    poll: {
                        agree: data.agree,
                        higher: data.higher,
                        lower: data.lower
                    }
                };
            })
        },
            (err) => {
                this.setState({
                    server_fail: true
                })
            })

        axios.get(global.server + "/professors/reviews/" + id).then((response) => {
            this.setState((state, props) => {
                return {
                    num_reviews: response.data.num_reviews, 
                    loading:false
                };
            });

        },
            (err) => {
                this.setState({
                    server_fail: true
                })
            })
    }
}

export default withRouter(ProfPage);